import { useRoutes } from "react-router-dom/dist";
import AuthLayout from "./layouts/Auth/AuthLayout";
import Home from "./layouts/Home";
import Landing from "./layouts/Landing/Landing";
import Login from "./views/Login";
import SignUp from "./views/SignUp";
import AdminLogin from "./views/Admin/Auth/AdminLogin";
import ErrorPage from "./components/ErrorBoundary/components/ErrorPage";
import AdminDashboard from "./layouts/Dashboard/AdminDashboard";
import ProtectedRoutes from "./components/ProtectedRoutes/ProtectedRoutes";
import AdminDash from "./views/Admin/Dashboard/AdminDash";
import Packages from "./views/Admin/Packages/Packages";
import Tournament from "./views/Admin/Tournament/Tournament";
import Rewards from "./views/Admin/Rewards/Rewards";
import Clubs from "./views/Admin/Clubs";
import ClubData from "./views/Admin/Clubs/components/ClubData";
import Chat from "./views/Admin/Chat";
import Notifications from "./views/Notifications";
import ShowClubData from "./views/Admin/Clubs/components/ShowClubData";
import { useSelector } from "react-redux";
export default function Router() {
  const isAuthenticated = useSelector((state) => state.admin.isAuthenticated);
  let element = useRoutes([
    {
      path: "/",
      element: <Landing />,
    },
    {
      element: <ProtectedRoutes isLogged={isAuthenticated} />,
      children: [
        {
          path: "admin",
          element: <AdminDashboard />,
          children: [
            { path: "dashboard", element: <AdminDash /> },
            { path: "packages", element: <Packages /> },
            { path: "clubs", element: <Clubs /> },
            { path: "club/:id", element: <ShowClubData /> },
            { path: "chat", element: <Chat /> },
            { path: "tournament", element: <Tournament /> },
            { path: "rewards", element: <Rewards /> },
          ],
        },
      ],
    },
    {
      path: "auth",
      element: <AuthLayout />,
      children: [
        { path: "login", element: <Login /> },
        { path: "register", element: <SignUp /> },
      ],
    },
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/notifications",
      element: <Notifications />,
    },
    {
      path: "/admin-login",
      element: <AdminLogin />,
    },
    {
      path: "*",
      element: <ErrorPage />,
    },
  ]);
  return element;
}
