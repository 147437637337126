import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import { db } from "../Firebase/Firebase";
import { set, ref, get, onValue, push } from "firebase/database";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import React, { useState, useEffect } from "react";
import { notificationsInvisibility } from "../store/actions/adminActions";
import { useDispatch } from "react-redux";

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "80vh",
  backgroundColor: "#000",
  color: "#fff",
  padding: theme.spacing(10),
}));
const StyledButton = styled(Button)(({ theme }) => ({
  background: "#000",
  marginRight: theme.spacing(1),
}));

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [notificationsPresence, setNotificationsPresence] = useState(false);
  const [notificationId, setNotificationId] = useState("");
  const [clubData, setClubData] = useState([]);
  const [loading, setLoading] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const getNotificationsData = async () => {
    try {
      const notificationsRefs = ref(db, "Notifications_Alert");
      const snapshot = await get(notificationsRefs);

      if (snapshot.exists()) {
        const values = Object.values(snapshot.val());
        console.log("notifications Data", values);
        setNotifications(values);
        setNotificationsPresence(true);
      } else {
        console.log("No data available");
        setNotificationsPresence(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getClubData = async () => {
    try {
      const clubDataRefs = ref(db, "clubData");
      const snapshot = await get(clubDataRefs);

      if (snapshot.exists()) {
        const values = Object.values(snapshot.val());
        setClubData(values);
      } else {
        console.log("No data available");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getNotificationsData();
    getClubData();
  }, []);

  const updateMembershipList = async (memberId) => {
    try {
      setLoading(true);
      const clubDataRef = ref(db, "clubData");
      const clubDataSnapshot = await get(clubDataRef);
      const clubDataValues = clubDataSnapshot.val();
      const notificationsRefs = ref(db, "Notifications");
      const notificationSnapshot = await get(notificationsRefs);
      const notificationDataValues = notificationSnapshot.val();

      if (clubDataValues && notificationDataValues) {
        for (const clubKey in clubDataValues) {
          const clubRef = ref(db, `clubData/${clubKey}`);
          const clubSnapshot = await get(clubRef);
          const clubData = clubSnapshot.val();

          if (clubData && clubData.tableDataList) {
            const tableDataList = clubData.tableDataList;

            for (const tableKey in tableDataList) {
              const tableRef = ref(
                db,
                `clubData/${clubKey}/tableDataList/${tableKey}`
              );
              const tableSnapshot = await get(tableRef);
              const tableData = tableSnapshot.val();

              if (
                tableData &&
                tableData.Membership &&
                tableData.Membership[memberId] === false
              ) {
                // Update membership with false value to true
                const updatedMembership = {
                  ...tableData.Membership,
                  [memberId]: true,
                };

                // Update tableData with updated membership
                const updatedTableData = {
                  ...tableData,
                  Membership: updatedMembership,
                };

                // Update tableData in Firestore
                await set(tableRef, updatedTableData);
              }
            }
          }
        }

        // Update notifications
        for (const notificationKey in notificationDataValues) {
          const notification = notificationDataValues[notificationKey];
          const tableMembershipIds = notification.table_membershipIds;

          if (tableMembershipIds) {
            for (const tableMembershipId of tableMembershipIds) {
              if (tableMembershipId[memberId] === false) {
                // Update table_membershipIds with true value
                tableMembershipId[memberId] = true;

                // Update notification in Firestore
                const notificationRef = ref(
                  db,
                  `Notifications/${notificationKey}`
                );
                await set(notificationRef, { ...notification });
              }
            }
          }
        }
        enqueueSnackbar(`MembershipId ${memberId} is Approved Successfully`, {
          variant: "success",
        });
        setLoading(false);
      } else {
        console.log("No clubData or notifications available");
      }
    } catch (error) {
      enqueueSnackbar("Error updating membership and notifications", {
        variant: "danger",
      });
    }
  };

  const updateMembershipId = async (notification) => {
    try {
      setLoading(true);
      setNotificationId(notification.id);
      const clubData = {};

      const clubRef = ref(db, notification.path);
      const clubSnapshot = await get(clubRef);
      const clubDataValue = clubSnapshot.val();

      const notificationsAlertRef = ref(
        db,
        `Notifications_Alert/${notification.id}`
      );
      const notificationAlertSnapshot = await get(notificationsAlertRef);
      const notificationAlertValue = notificationAlertSnapshot.val();
      // console.log("notificationAlert----", notificationAlertValue);

      // console.log("clubDataValue======", clubDataValue);

      clubData[notification.indexKey] = notification.indexvalue;
      // console.log("clubRef", clubRef);
      // console.log("club data path", clubData);

      await set(clubRef, { ...clubDataValue, ...clubData });
      await set(notificationsAlertRef, {
        ...notificationAlertValue,
        read: true,
      });
      enqueueSnackbar(
        `membership id ${notificationAlertValue.indexKey} is approved successfully.`,
        {
          variant: "success",
        }
      );
      setNotificationId(-1);
      setLoading(false);
      getNotificationsData();
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar(error, {
        variant: "danger",
      });
    }
  };

  const addNotifications = async () => {
    try {
      const notificationsRef = ref(db, "Notifications_Alert");
      const newNotificationsRef = push(notificationsRef);
      const notificationId = newNotificationsRef.key;
      const notification = {
        indexKey: 6,
        gameType: "HoIdem",
        club: "A",
        tableDataList: 1,
        FyHuTEmaGUgBHoTnGaemHZF1hTz2: false,
        notificationType: "insideTable",
      };
      const updatedFormValues = { ...notification, id: notificationId };
      await set(newNotificationsRef, updatedFormValues);

      enqueueSnackbar("Data Added Successfully", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error adding package data:", error);
    }
  };

  useEffect(() => {
    const notificationsRef = ref(db, "Notifications_Alert");

    const unsubscribe = onValue(notificationsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const notificationsArray = Object.values(data);
        console.log("outside of unscribe notifications", notifications);
        console.log("outside of unscribe live Data", notificationsArray);
        if (
          notifications.length &&
          JSON.stringify(notificationsArray) !== JSON.stringify(notifications)
        ) {
          dispatch(notificationsInvisibility(false));
        }
        setNotifications(notificationsArray);
      } else {
        setNotifications([]);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch, notifications]);

  return (
    <StyledRoot>
      <Typography variant="h4" fontWeight="bold">
        Notifications
      </Typography>
      <Divider sx={{ background: "#fff", my: 2 }} />

      <Grid container spacing={2}>
        {!notificationsPresence ? (
          <Typography
            sx={{
              textAlign: "center",
              py: 4,
              width: "100%",
              fontSize: { xs: "14px", sm: "17px", md: "19px", lg: "22px" },
              fontWeight: { xs: 400, md: 500 },
            }}
          >
            No Notifications
          </Typography>
        ) : !notifications.length ? (
          <RotatingLines
            strokeColor="white"
            strokeWidth="5"
            animationDuration="0.75"
            width="100"
          />
        ) : (
          notifications.map((val, index) => {
            return (
              !val.read && (
                <Grid item key={`${val.id}-${index}`} xs={12} lg={12}>
                  <Card>
                    <CardContent>
                      {val.notificationType === "clubJoin" ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: {
                              xs: "center",
                              md: "space-between",
                            },
                            gap: 2,
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Avatar src="/assets/images/user.png" />
                            <Typography
                              sx={{
                                ml: 1,
                                fontSize: {
                                  xs: "12px",
                                  sm: "14px",
                                  md: "18px",
                                },
                                fontWeight: "bold",
                              }}
                            >
                              {`Club ${val?.metadata?.club}  with userId ${val?.metadata?.userId} wants the Approval`}
                            </Typography>
                          </Box>
                          <Box
                            flex={1}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 1,
                            }}
                          >
                            <StyledButton
                              variant="contained"
                              onClick={() => updateMembershipId(val)}
                            >
                              {notificationId === val.id && (
                                <RotatingLines
                                  strokeColor="white"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  width="30"
                                  visible={loading}
                                />
                              )}
                              Accept
                            </StyledButton>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: {
                              xs: "center",
                              md: "space-between",
                            },
                            gap: 2,
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Avatar src="/assets/images/user.png" />
                            <Typography
                              sx={{
                                ml: 1,
                                fontSize: {
                                  xs: "12px",
                                  sm: "14px",
                                  md: "18px",
                                },
                                fontWeight: "bold",
                              }}
                            >
                              {`Club ${val?.metadata?.club} of Table Index ${val?.metadata?.tableIndex} with chipsRequest ${val?.metadata?.chipsRequest} & amount ${val?.metadata?.amount} wants the Approval`}
                            </Typography>
                          </Box>
                          <Box
                            flex={1}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 1,
                            }}
                          >
                            <StyledButton
                              variant="contained"
                              onClick={() => updateMembershipId(val)}
                            >
                              {notificationId === val.id && (
                                <RotatingLines
                                  strokeColor="white"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  width="30"
                                  visible={loading}
                                />
                              )}
                              Accept
                            </StyledButton>
                          </Box>
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              )
            );
          })
        )}
      </Grid>
    </StyledRoot>
  );
};

export default Notifications;
